.App { text-align: center; min-height: 100vh; display: flex; flex-direction: column; } 
.App-header { background-color: #E5EEFC; padding: 20px; display: flex; align-items: center; justify-content: center; color: black; position: fixed; width: 100%; top: 0; z-index: 10; } 
.header-title { margin: 0; font-size: 28px; font-weight: bold; } 
nav ul { list-style-type: none; padding: 0; } 
nav li { display: inline; margin: 0 15px; font-weight: 600; cursor: pointer; } 
nav li.bold { font-weight: bold; } 
main { flex: 1; padding: 20px; padding-top: 80px; margin-bottom: 20px; } 
.web-servers { text-align: left; } 
.header-button {
    background-color: #C4CEDC; 
    color: black; 
    padding: 10px 20px; 
    border-radius: 5px; 
    text-decoration: none; 
    font-weight: bold; 
    font-size: 0.9em; 
    transition: background-color 0.3s ease; 
  }
  
  .header-button:hover {
    background-color: #A3B3C6; 
    color: white; 
  }
  
.web-servers h2 { margin-top: 0; } 
.web-servers hr { border: 1px solid #000; } 
.server-cards { display: flex; gap: 20px; flex-wrap: wrap; justify-content: center; } 
.server-card { perspective: 1000px; width: 250px; margin: 20px; } 
.server-card-link { text-decoration: none; color: inherit; } 
.flip-card { background-color: transparent; width: 100%; height: 300px; border: 1px solid #ccc; border-radius: 10px; overflow: hidden; cursor: pointer; } 
.flip-card-inner { position: relative; width: 100%; height: 100%; text-align: center; transition: transform 0.6s; transform-style: preserve-3d; } 
.flip-card:hover .flip-card-inner { transform: rotateY(180deg); } 
.flip-card-front, .flip-card-back { position: absolute; width: 100%; height: 100%; -webkit-backface-visibility: hidden; backface-visibility: hidden; display: flex; flex-direction: column; justify-content: center; align-items: center; } 
.flip-card-front { background-color: #f0f0f0; color: black; } 
.flip-card-back { background-color: #87CEEB; color: white; transform: rotateY(180deg); } 
.flip-card-back p { font-size: 16px; font-weight: bold; margin: 10px; } 
.server-image { width: 80%; height: 150px; background-color: #e0e0e0; margin-bottom: 10px; } 
.server-title { font-size: 18px; font-weight: bold; padding: 0 10px; text-align: center; margin: 0; word-wrap: break-word; } 
.server-description { font-size: 16px; padding: 0 10px; text-align: center; } 
footer { background-color: #C4CEDC; padding: 10px 20px; border-top: 1px solid #ccc; display: flex; flex-direction: column; justify-content: center; align-items: center; font-size: 14px; box-shadow: 0 -2px 5px rgba(0,0,0,0.1); position: relative; } 
.footer-content { display: flex; flex-direction: column; align-items: center; } 
.footer-left, .footer-right { display: flex; align-items: center; margin: 5px 0; } 
.footer-right a { text-decoration: none; color: #333; margin: 0 10px; display: flex; align-items: center; } 
.footer-right a:hover { color: #007bff; } 
.footer-right a i { margin-right: 5px; } 
.footer-logo { margin-top: 10px; } 
.utd-logo { width: 150px; height: auto; } 
@media (min-width: 600px) { .footer-content { flex-direction: row; justify-content: space-between; width: 100%; } } 
a { text-decoration: none; color: inherit; } 
.hide-header-footer { display: none; } 
.server-image {
  width: 100%;
  height: 200px; 
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat; 
  border-radius: 10px;
}

.full-page { flex: 1; display: flex; flex-direction: column; justify-content: center; align-items: center; }
