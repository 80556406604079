.home-button {
    color: white;
    font-size: 24px;
    margin-right: 10px;
    text-decoration: none;
    display: flex;
    align-items: center;
  }
  
  .home-button:hover {
    color: lightgray;
  }

  .home-logo {
    width: 80px;
    height: auto;
    margin: 0 8px; 
  }
  
  